import styled, {css} from 'styled-components';
import Helpers from 'common/Theme/Helpers';
import Color from 'common/Theme/Colors';



export const WrapStyle = styled.div<{isHasCart : boolean}>`
    max-width: 500px;
    margin: auto;
  padding-bottom: ${props => props.isHasCart ? '120px' : '50px'};
  min-height: calc(100vh - ${props => props.isHasCart ? '120px' : '50px'});
  ${props => props.variant === 'primary' && css`
    background: ${Color.white}
  `}
  ${props => props.variant === 'secondary' && css`
    background: ${Color.bg}
  `}
`;
