import styled, {css} from 'styled-components';
import Color from 'common/Theme/Colors';

export const WrapStyle = styled.div`
  ${props => props.variant === 'primary' && css`
    background: ${Color.white}
  `}
  ${props => props.variant === 'secondary' && css`
    background: ${Color.bg}
  `}
`;

export const ContentStyle = styled.div`
  min-height: calc(100vh - 400px);
  
`;