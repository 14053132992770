import React, {useEffect} from "react";
import {useAuth, useProfile} from "modules/User/hooks";
import {RootState} from "store";
import {useSelector} from "react-redux";
import {WrapStyle} from "./styles";
import PhoneButton from "shared/PhoneButton";
import DesktopMainLayout from "common/layouts/DesktopMainLayout";
import {DetectMobileContext} from 'shared/DeviceDetect';
import {useRouter} from "next/router";


export default function Layout({ children, variant, isMobile,isHidePhone, ...rest }) {
    const {isAuth} = useAuth()
    const {isHasProfile, profileSync} = useProfile()
    const isHasCart = useSelector((state:RootState) => state.cart.data.cartItems)?.length



    //console.log('router', router)


    useEffect(() => {
        if(isAuth && !isHasProfile){
            profileSync()
        }

    }, [isAuth])



    if(!isMobile) {
        return (
            <DetectMobileContext.Provider value={isMobile}>
                <DesktopMainLayout variant={variant} {...rest}>
                    {children}
                </DesktopMainLayout>
            </DetectMobileContext.Provider>
        )
    }

    return (
        <DetectMobileContext.Provider value={isMobile}>
            <WrapStyle
                {...rest}
                variant={variant}
                isHasCart={Boolean(isHasCart?.length)}
            >
                <div>

                    {children}
                </div>
                {
                    !isHidePhone && !isHasCart && (
                        <PhoneButton isHasCart={Boolean(isHasCart?.length)} />
                    )
                }
            </WrapStyle>
        </DetectMobileContext.Provider>

    )
}
