import React, {useEffect} from "react";
import {useAuth, useProfile} from "modules/User/hooks";
import {WrapStyle, ContentStyle} from "./styles";
import HeaderDesktop from "shared/HeaderDesktop";
import HeaderDesktopMiniSite from "shared/HeaderDesktopMiniSite";
import Footer from "shared/Footer";
import {useRouter} from "next/router";
import NProgress from "nprogress";
import {useMiniSiteDetect} from "common/context/MiniSiteDetect";


export default function Layout({ children, variant }) {
    const {isAuth} = useAuth()
    const {isHasProfile, profileSync} = useProfile()
    const router = useRouter()
    const isMiniSite = useMiniSiteDetect()


    useEffect(() => {
        if(isAuth && !isHasProfile){
            profileSync()
        }
    }, [isAuth])

    useEffect(() => {
        const handleRouteChangeStart = () => {
            NProgress.start()
        }

        const handleRouteChangeEnd = () => {
            NProgress.done();
        }

        router.events.on('routeChangeStart', handleRouteChangeStart)
        router.events.on('routeChangeComplete', handleRouteChangeEnd)
        return () => {

            router.events.off('routeChangeStart', handleRouteChangeStart)
            router.events.off('routeChangeComplete', handleRouteChangeEnd)
        }
    }, [])




    return (
        <WrapStyle variant={variant}>
            <ContentStyle>
                {
                    isMiniSite ? <HeaderDesktopMiniSite/> : <HeaderDesktop/>
                }


                {children}
            </ContentStyle>
            {
                !isMiniSite && (
                    <Footer/>
                )
            }

        </WrapStyle>
    )
}
