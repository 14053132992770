import styled, {css} from 'styled-components';
import { Fonts, Colors } from 'common/Theme/index';
import Helpers from 'common/Theme/Helpers';

export const HeaderStyle = styled.div`
  ${Helpers.css.flex};
  ${Helpers.css.flex_ac};
  ${Helpers.css.flex_sb};
  padding:11px 10px;
  //width: 400px;

  //margin-bottom
  ${props => props.mb && css`
      margin-bottom: ${Helpers.css.mb[props.mb]};
  `}
  //end margin-bottom
`;

export const HeaderRightStyle = styled.div`
  margin-left: auto;
  ${Helpers.css.flex};
  ${Helpers.css.flex_ac};
`;

export const HeaderLogoStyle = styled.div`
  ${Helpers.css.flex};
  ${Helpers.css.flex_center};
  box-sizing: content-box;
  width: 178px;
  height: 38px;
  position: relative;
`;

export const HeaderLeftStyle = styled.div`
`;

export const HeaderCenterStyle = styled.div`
  ${Helpers.css.flex};
  ${Helpers.css.flex_ac};
  ${Helpers.css.cursor_pointer};
  background: #FFFFFF;
  border-radius: 24px;
  height: 42px;
  margin: 0 5px;
  padding: 0 20px;
  padding-right: 30px;
  white-space: nowrap;
  div{
  }
`;

export const Wrap = styled.div`
  position: sticky;
  top: 0;
  ${Helpers.css.flex};
  ${Helpers.css.flex_ac};
  background: #eeeeee;
  padding: 0 40px;
  height: 70px;
  z-index: 5;
  filter: drop-shadow(2px 0px 2px rgba(0,0,0,0.3));
`;

export const HeaderIconStyle = styled.div`
  margin-right: 8px;
`;

export const HeaderPhoneStyle = styled.div`
  margin-left: auto;
  margin-right: 70px;
  ${Helpers.css.flex};
  ${Helpers.css.flex_ac};
  white-space: nowrap;

  @media screen and (max-width: 1500px){
    margin-right: 20px;
  }

  @media screen and (max-width: 1350px){
    margin-right: 0px;
  }
`;

export const HeaderPhoneIconStyle = styled.div`
  margin-right: 10px;
`;

export const WrapMap = styled.div`
  height: 85vh;
  //overflow: auto;
`;
