import React from 'react';
import {
    FooterWrapperStyle,
    FooterStyle,
    RowStyle,
    CollumnStyle,
    TitleWrapStyle,
    RowBottomStyle,
    LinkWrapStyle,
    MobileLinksStyle,
    StoreAppleButtonStyle,
    StoreGoogleButtonStyle,
    FooterLogoWrapStyle,
    CopyRightStyle,
    FooterMidWrapStyle
} from "./styles";
import Typography from "shared/Typography/index";
import Link from "shared/Link";
import Image from "shared/Image";
import links from "helper/links";
import imgAppstore from "public/img/app_store_badge.svg";
import imgGoogle from "public/img/google-play-badge.png";
import logoGreen from "public/img/logo_full_green.svg";
import AppLinks from "shared/AppLinks/AppLinks";
//import logoGreen from "public/img/logo_ft_e.svg";

const Footer = ({}) => {

    const linksFooter = {
        partners: links.abs.partnerPage,
        agreement: links.abs.agreement,
        about: links.abs.about,
        bonus: '/bonus',
        blog: '/blog',
        reviews: '/reviews',
        pizza: '/pizza',
        rollySushi: '/rolly-sushi',
        shashlyk: '/shashlyk',
        chudu: '/chudu',
        burger: '/burger',
        partnerSingIn: '/partner',
    }

    return (
        <FooterWrapperStyle data-cy={'footer'}>
            <FooterStyle>
                <RowStyle>
                    <CollumnStyle>
                        <TitleWrapStyle><Typography variant="textSmall" color="white" mb="2.5"
                                                    isBold>Компания</Typography></TitleWrapStyle>
                        <LinkWrapStyle>
                            <Link href={linksFooter.partners}><Typography variant="textSmall" color="grayMid" mb="1.5">Информация
                                для партнеров</Typography></Link>
                        </LinkWrapStyle>
                        <LinkWrapStyle>
                            <Link href={linksFooter.agreement}><Typography variant="textSmall" color="grayMid" mb="1.5">Пользовательское
                                соглашение</Typography></Link>
                        </LinkWrapStyle>
                        <LinkWrapStyle>
                            <Link href={linksFooter.partnerSingIn} target='_blank'>
                                <Typography variant="textSmall" color="grayMid" mb="1.5">
                                    Вход для партнеров
                                </Typography>
                            </Link>
                        </LinkWrapStyle>
                    </CollumnStyle>
                    <CollumnStyle>
                        <TitleWrapStyle><Typography variant="textSmall" color="white" mb="2.5" isBold>Полезные
                            ссылки</Typography></TitleWrapStyle>
                        <LinkWrapStyle>
                            <Link href={linksFooter.about}><Typography variant="textSmall" color="grayMid" mb="1.5">О
                                проекте</Typography></Link>
                        </LinkWrapStyle>
                        <LinkWrapStyle>
                            <Link href={linksFooter.bonus}><Typography variant="textSmall" color="grayMid" mb="1.5">Бонусные
                                баллы</Typography></Link>
                        </LinkWrapStyle>
                        {/*<LinkWrapStyle>
                            <Link href={linksFooter.blog}><Typography variant="textSmall" color="grayMid"
                                                                mb="1.5">Блог</Typography></Link>
                        </LinkWrapStyle>*/}
                        {/*<LinkWrapStyle>
                            <Link href={linksFooter.reviews}><Typography variant="textSmall" color="grayMid"
                                                                   mb="1.5">Отзывы</Typography></Link>
                        </LinkWrapStyle>*/}
                    </CollumnStyle>
                    <CollumnStyle>
                        <TitleWrapStyle><Typography variant="textSmall" color="white" mb="2.5" isBold>Быстрые
                            ссылки</Typography></TitleWrapStyle>
                        <LinkWrapStyle>
                            <Link href={linksFooter.pizza}><Typography variant="textSmall" color="grayMid" mb="1.5">Доставка
                                пиццы</Typography></Link>
                        </LinkWrapStyle>
                        <LinkWrapStyle>
                            <Link href={linksFooter.rollySushi}><Typography variant="textSmall" color="grayMid" mb="1.5">Доставка
                                суши и роллов</Typography></Link>
                        </LinkWrapStyle>
                        <LinkWrapStyle>
                            <Link href={linksFooter.shashlyk}><Typography variant="textSmall" color="grayMid" mb="1.5">Шашлык
                                на заказ</Typography></Link>
                        </LinkWrapStyle>
                        <LinkWrapStyle>
                            <Link href={linksFooter.chudu}><Typography variant="textSmall" color="grayMid" mb="1.5">Доставка
                                чуду</Typography></Link>
                        </LinkWrapStyle>
                        <LinkWrapStyle>
                            <Link href={linksFooter.burger}><Typography variant="textSmall" color="grayMid" mb="1.5">Заказать
                                бургеры</Typography></Link>
                        </LinkWrapStyle>
                    </CollumnStyle>
                    <CollumnStyle>
                        <AppLinks />
                    </CollumnStyle>
                </RowStyle>
                <FooterMidWrapStyle>
                    <FooterLogoWrapStyle>
                        <Link href="/">
                            <Image
                                //quality={100}
                                src={logoGreen}
                            />
                        </Link>
                    </FooterLogoWrapStyle>
                    <Typography variant="textSmall" color="transparent" isBold>
                        {process.env.NEXT_PUBLIC_VERSION_LABEL} <br/>
                        Дата сборки ({process.env.NEXT_PUBLIC_BUILD_DATE}) <br/>
                    </Typography>
                </FooterMidWrapStyle>

                <RowBottomStyle>
                    <CopyRightStyle>
                        <Typography variant="textSmall" color="gray" isBold>
                            © 2023 Edostav.ru – единая система заказа еды в г. Махачкала.
                            <br/>
                            Все права защищены.
                        </Typography>
                    </CopyRightStyle>
                    {/*<SocialWrapStyle>
                        <Link
                            href="https://www.facebook.com/edostav?roistat_visit=5424548"
                            target="_blank"
                        ><LogoSocialWrapStyle><Image src="/img/facebook_logo.svg"/></LogoSocialWrapStyle></Link>
                        <Link
                            href="https://www.youtube.com/channel/UCvHhwEzD_xJcC1NimgvzA8w?roistat_visit=5424548"
                            target="_blank"
                        ><LogoSocialWrapStyle><Image src="/img/you_tube_logo.svg"/></LogoSocialWrapStyle></Link>
                        <Link
                            href="https://www.instagram.com/edostav?roistat_visit=5424548"
                            target="_blank"
                        ><LogoSocialWrapStyle><Image src="/img/instagram_logo.svg"/></LogoSocialWrapStyle></Link>
                        <Link
                            href="https://vk.com/edostav?roistat_visit=5424548"
                            target="_blank"
                        ><LogoSocialWrapStyle><Image src="/img/vk_logo.svg"/></LogoSocialWrapStyle></Link>
                    </SocialWrapStyle>*/}
                </RowBottomStyle>
            </FooterStyle>
        </FooterWrapperStyle>
    )
}

export default React.memo(Footer);
