class YandexMetrika {
    private readonly ym = null;
    private readonly dataLayer = null;
    private readonly id = null;

    constructor(ya, dataLayer, id) {
        this.ym = ya;
        this.dataLayer = dataLayer;
        this.id = id;
    }

    reportEvent = (
        eventName: string,
        attributes?: null | Object,
    ): Promise<void> => {
        return this.ym(this.id, "reachGoal", eventName);
    };

    yaEcommerce = (object: any) => {

        console.log('yaEcommerce', object);

        if(window.dataLayer){
            this.dataLayer.push(object)
        }
    }
}

const ym = typeof window !== "undefined" && window.ym ? window.ym : () => {};
const dataLayer = typeof window !== "undefined" && window.dataLayer ? window.dataLayer : {};



export default new YandexMetrika(ym, dataLayer, 43800449);

/*class YandexMetrika {
    private yaCounter = null;
    //private id = null;

    constructor(yaCounter) {
        this.yaCounter = yaCounter;
        //this.id = id;
    }

    reportEvent = (eventName: string, attributes?: null | Object): Promise<void> => {
        console.log(this.yaCounter)
        return this.yaCounter.reachGoal(eventName);
    }
}


const yaCounter = (typeof window !== 'undefined' && window[`yaCounter43800449`]) ? window[`yaCounter43800449`] : () => {}
console.log('typeof window', typeof window, yaCounter)

export default new YandexMetrika(yaCounter);*/
