import React, {useCallback, lazy, Suspense, useEffect, memo} from 'react';
import {
    HeaderStyle,
    HeaderCenterStyle,
    HeaderLogoStyle,
    Wrap,
    HeaderRightStyle,
    HeaderIconStyle,
    HeaderPhoneStyle,
    HeaderPhoneIconStyle,
    WrapMap
} from "./styles";
import Typography from "shared/Typography/index";
import Button from "shared/Button/index";
import Link from "shared/Link";
import Icon from "shared/Icon";
import SlidePanel, {useSlidePanel} from "shared/SlidePanel";
import {links} from "helper";
import useAddress from "modules/Map/hooks/useAddress";
import useMap from "modules/Map/hooks/useMap";
import {useAuth} from "modules/User/hooks";
import {CartDesktopButton} from "modules/Cart/components";
import Image from "shared/Image";
//import logo from "public/icons/logo.svg"
import logo from "public/img/logo_e.svg";

const MapComponentLazy = lazy(() => import("modules/Map/components/MapComponent"))
const AddressListUserLazy = lazy(() => import("modules/Map/components/AddressListSlide"))

const Header = ({ mb, onOpen, ...rest }) => {
    const {addressLabel} = useMap()
    const {
        address,
        onSelectMyAddress,
        onSelectAddress,
    } = useAddress()
    const {isAuth} = useAuth()
    const [actions, setActions] = useSlidePanel()


    const onGoMap = useCallback(() => {
            actions.onOpen()
    }, [actions]);

    const handleSelectAddress = () => {
        onSelectMyAddress()
        actions.onClose()
    }



    return (
        <Wrap data-cy={'desktop-header'}>

            <HeaderStyle
                mb={mb}
                {...rest}
            >
                <HeaderCenterStyle onClick={onGoMap} data-cy={'header-desktop'}>
                    <HeaderIconStyle>
                        <Icon name={"LocationIcon"} />
                    </HeaderIconStyle>
                    <Typography maxLength={50} color={'green'} variant="h4" >
                        {
                            addressLabel || "Укажите адрес"
                        }
                    </Typography>
                </HeaderCenterStyle>
            </HeaderStyle>

            <HeaderRightStyle data-cy={'header-desktop'}>
                <HeaderPhoneStyle>
                    <HeaderPhoneIconStyle>
                        <Icon color={'gray'} width={20} height={20}  name={'CallIcon'}/>
                    </HeaderPhoneIconStyle>
                    <Typography color={'black'}  variant={'h3'}>+7(967) 931-20-90</Typography>
                </HeaderPhoneStyle>

                <CartDesktopButton/>


                <Link href={links.abs.search} passHref data-cy={'header-desktop'}>
                    <Button as={'a'} icon={'SearchIcon'} variant={'transparent'}>
                        Поиск
                    </Button>
                </Link>

                {
                    isAuth ? (
                        <Link href={links.abs.myOrders} passHref data-cy={'header-desktop'}>
                            <Button as={'a'} variant={'transparent'} >
                                Личный кабинет
                            </Button>
                        </Link>
                    ) : (
                        <Link href={links.abs.authRequest} passHref data-cy={'header-desktop'}>
                            <Button as={'a'} variant={'transparent'} >
                                Авторизация
                            </Button>
                        </Link>
                    )
                }



            </HeaderRightStyle>
            <SlidePanel initSheet={setActions} closeOnDocumentClick={false} data-cy={'header-desktop'}>
                    <Suspense fallback={<div>Loading</div>}>
                        {
                            isAuth && address.data?.length ? (
                                <>
                                    <AddressListUserLazy onSelectAddress={onSelectAddress}/>
                                    <Button onClick={handleSelectAddress} fullSize variant='primary'>Готово</Button>
                                </>
                            ) : (
                                <WrapMap>
                                    <MapComponentLazy showCloseButton={false}/>
                                </WrapMap>
                            )
                        }
                    </Suspense>
            </SlidePanel>
        </Wrap>
    );
};

export default memo(Header);
