import styled, {css} from 'styled-components';
import { Fonts, Colors } from 'common/Theme/index';
import Helpers from 'common/Theme/Helpers';

export const FooterWrapperStyle = styled.div`
  background: #333;
`;

export const FooterStyle = styled.footer`
  height: 400px;
  padding: 53px 0 0;
  position: relative;
  margin: 0 auto;
  max-width: 1008px;
  ${Helpers.css.flex};
  ${Helpers.css.flex_vertical};
  ${Helpers.css.flex_sb};
`;

export const RowStyle = styled.div`
  position: relative;
  ${Helpers.css.flex};
  ${Helpers.css.flex_sb};
`;

export const CollumnStyle = styled.div`
`;

export const TitleWrapStyle = styled.div`
  text-transform: uppercase;
`;

export const RowBottomStyle = styled.div`
  border-top: 1px solid #535353;
  color: #b2b2b2;
  height: 79px;
  ${Helpers.css.flex};
  ${Helpers.css.flex_ac};
  ${Helpers.css.flex_sb};
`;

export const LinkWrapStyle = styled.div`
  ${Helpers.css.cursor_pointer};
`;

export const MobileLinksStyle = styled.div`
  ${props => (props.isMobile && css`
    display: flex;
    align-items: center;
    img {
    object-fit: contain;
    height: 100%;
    width: 100%;
    }
  `)}
`;

export const StoreAppleButtonStyle = styled.div`
  height: 51px;
  width: 153px;
  position: relative;
  ${Helpers.css.cursor_pointer};
  margin-bottom: 15px;
  
   ${props => (props.isMobile && css`
     width: 50%;
   height: 35px;
     margin: 0;
  `)}
`;

export const StoreGoogleButtonStyle = styled.div`
  height: 51px;
  width: 174px;
  position: relative;
  ${Helpers.css.cursor_pointer};
  margin: 0 -10px;
  
  ${props => (props.isMobile && css`
       width: 63%;
    height: 49px;
   margin: 0;
  `)}
`;

export const FooterLogoWrapStyle = styled.div`
  display: block;
  height: 47px;
  width: 225px;
  position: relative;
  ${Helpers.css.cursor_pointer};
  margin-bottom: 10px;
`;

export const CopyRightStyle = styled.div`
`;

export const SocialWrapStyle = styled.div`
  ${Helpers.css.flex};
`;

export const LogoSocialWrapStyle = styled.div`
  position: relative;
  width: 36px;
  height: 36px;
  ${Helpers.css.cursor_pointer};
  margin: 0 10px;
`;


export const FooterMidWrapStyle = styled.div`
  ${Helpers.css.flex};
  ${Helpers.css.flex_sb};
  ${Helpers.css.flex_ac};
`;
