import {WrapperStyle} from "./styles";
import Button from "shared/Button";
import useCity from "modules/Map/hooks/useCity";
import {useMiniSiteDetect} from "common/context/MiniSiteDetect";
import {usePartnerOpen} from "modules/Partner/hooks";
import {useMobileContextDetect} from "shared/DeviceDetect";
import YandexMetrika from "modules/YandexMetrika";
import IMask from "imask";

const phoneMask = IMask.createMask({
    mask: '+{7} (000) 000-00-00',
});

const PhoneButton = ({isHasCart}) => {
    const {citySelected} = useCity()
    const {partner} = usePartnerOpen()
    const isMiniSite = useMiniSiteDetect()
    const isMobile = useMobileContextDetect();

    if(isMiniSite && !partner?.data?.phone){
        return <></>
    }

    const handleClickForYm = () => {
        if(isMobile){
            YandexMetrika.reportEvent('knopka-phone')
        }
    }

    return (
        <WrapperStyle isHasCart={isHasCart} data-cy={'phone-button'} onClick={handleClickForYm}>
            <Button component={'a'} href={`tel:${citySelected?.callcenterPhone}`} size={'normal'} icon={'CallIcon'}>
                {
                    isMiniSite ? phoneMask.resolve(partner.data.phone) : citySelected?.callcenterPhone
                }
            </Button>
        </WrapperStyle>
    )
}

export default PhoneButton
