import React, {useEffect, useRef, useState} from 'react';
import {MobileLinksStyle, StoreAppleButtonStyle, StoreGoogleButtonStyle, TitleWrapStyle} from "shared/Footer/styles";
import Typography from "shared/Typography";
import Link from "shared/Link";
import Image from "shared/Image";
import imgAppstore from "@public/img/app_store_badge.svg";
import imgGoogle from "@public/img/google-play-badge.png";
import sbjs from 'sourcebuster';

const generateAppmetricaLink = (Sbjs) => {

    return `https://redirect.appmetrica.yandex.com/serve/892874078564683190?utm_source=${Sbjs.get.current.src}&utm_medium=${Sbjs.get.current.mdm}&utm_campaign=${Sbjs.get.current.cmp}&utm_content=${Sbjs.get.current.cnt}&utm_term=${Sbjs.get.current.trm}`
}

const androidStoreLink = 'https://play.google.com/store/apps/details?id=ru.diit.dostavka05'
const iosStoreLink = 'https://itunes.apple.com/ru/app/доставка05/id999363875'

const AppLinks = ({isHideHead, isMobile}) => {
    const [sbjsLink, setSbjsLink] = useState(null);

    useEffect(() => {
        if(isMobile){
            sbjs.init();
            setSbjsLink(generateAppmetricaLink(sbjs))

        }

    }, [])


    return (
        <>
            {
                !isHideHead && (
                    <TitleWrapStyle><Typography variant="textSmall" color="white" mb="2.5" isBold>Наши
                        приложения</Typography></TitleWrapStyle>
                )
            }

            <MobileLinksStyle isMobile={isMobile}>
                <StoreAppleButtonStyle isMobile={isMobile}>
                    <Link
                        //href="https://itunes.apple.com/ru/app/доставка05/id999363875"
                        href={sbjsLink || iosStoreLink}
                        target="_blank"
                        rel="nofollow"
                    >
                        <a><Image objectFit='contain' src={imgAppstore}/></a>
                    </Link>
                </StoreAppleButtonStyle>
                <StoreGoogleButtonStyle isMobile={isMobile}>
                    <Link
                        //href="https://play.google.com/store/apps/details?id=ru.diit.dostavka05"
                        href={sbjsLink || androidStoreLink}
                        target="_blank"
                        rel="nofollow"
                    >
                        <a><Image objectFit='contain' src={imgGoogle}/></a>
                    </Link>
                </StoreGoogleButtonStyle>
            </MobileLinksStyle>
        </>
    );
};

export default AppLinks;
